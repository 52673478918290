<template>
  <template v-if="editionMode">
    <div
      class="relative flex w-full rounded-md border border-slate-300 bg-white px-3 py-2 shadow-sm focus-within:border-primary-400 focus-within:ring-0"
      :class="label ? 'mt-2' : ''"
    >
      <label
        v-if="label"
        :for="name"
        class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >{{ label }}
        <span v-if="label && required" class="text-red-600">*</span>
      </label>

      <VDatePicker
        v-model.range="dateValue"
        :popover="{
          visibility: 'click'
        }"
        :view="view"
        :locale="$i18n.locale.replace('_', '-')"
        :min-date="minDate"
        :max-date="maxDate"
        :columns="2"
        :is-required="required"
      >
        <template #default="{ inputValue, inputEvents }">
          <div class="flex w-full items-center justify-around pr-3">
            <input
              v-mask="['##/##/####', '##-##-####']"
              type="text"
              :value="inputValue.start"
              class="min-w-0 flex-1 border-0 p-0 text-right text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 sm:text-sm"
              v-on="inputEvents.start"
            />
            <span class="shrink-0 px-4">
              <FontAwesomeIcon :icon="['fal', 'chevron-right']" />
            </span>
            <input
              v-mask="['##/##/####', '##-##-####']"
              type="text"
              :value="inputValue.end"
              class="min-w-0 flex-1 border-0 p-0 text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 sm:text-sm"
              v-on="inputEvents.end"
            />
          </div>
        </template>
      </VDatePicker>
      <button
        v-if="!required"
        type="button"
        tabindex="-1"
        class="absolute inset-y-0 right-0 mr-3 flex items-center pl-3 text-red-600"
        @click.prevent="$emit('update:date', null)"
      >
        <FontAwesomeIcon :icon="['fal', 'times']" />
      </button>
    </div>
    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help -mt-1 text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </template>
  <template v-else>
    <BaseShowLabel
      :label="label"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </template>
</template>

<script>
import moment from 'moment'
import { DatePicker as VDatePicker } from 'v-calendar'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseShowEditDateRangePicker',
  components: { FontAwesomeIcon, BaseShowLabel, VDatePicker },
  props: {
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    view: {
      type: String,
      required: false,
      default: 'monthly'
    },
    disableDatePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        ;[]
      }
    },
    todayDefault: {
      type: Boolean,
      required: false,
      default: true
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:start', 'update:end'],
  data() {
    return {}
  },
  computed: {
    dateValue: {
      get() {
        return {
          start: moment(this.start).toDate(),
          end: moment(this.end).toDate()
        }
      },
      set(value) {
        this.$emit('update:start', moment(value.start).format('YYYY-MM-DD'))
        this.$emit('update:end', moment(value.end).format('YYYY-MM-DD'))
      }
    },
    cDisplayedValueWhenNotEditionMode() {
      let _moment = moment(this.dateValue)
      if (_moment.isValid()) {
        return _moment.format('DD/MM/YYYY')
      }
      return ''
    }
  }
}
</script>
