<template>
  <div
    class="my-2 flex divide-x divide-slate-400 rounded border border-slate-400"
  >
    <button
      v-if="diffDay > 1"
      class="flex-1"
      :class="[buttonClass]"
      @click="decreaseRange"
    >
      <FontAwesomeIcon :icon="['fal', 'chevron-double-left']" fixed-width />
    </button>
    <button class="flex-1" :class="[buttonClass]" @click="decreaseDay">
      <FontAwesomeIcon :icon="['fal', 'chevron-left']" fixed-width />
    </button>
    <div>
      <VDatePicker
        v-model.range="datePickerValues"
        :popover="{
          visibility: 'click'
        }"
        :locale="$i18n.locale.replace('_', '-')"
        :columns="2"
        @dayclick="handleDayClick"
      >
        <template #default="{ inputEvents }">
          <button :class="buttonClass" v-on="inputEvents.start">
            <FontAwesomeIcon :icon="['fal', 'calendar']" fixed-width />
          </button>
        </template>
      </VDatePicker>
    </div>
    <button class="flex-1" :class="[buttonClass]" @click="increaseDay">
      <FontAwesomeIcon :icon="['fal', 'chevron-right']" fixed-width />
    </button>
    <button
      v-if="diffDay > 1"
      class="flex-1"
      :class="[buttonClass]"
      @click="increaseRange"
    >
      <FontAwesomeIcon :icon="['fal', 'chevron-double-right']" fixed-width />
    </button>
  </div>
</template>

<script>
import { DatePicker as VDatePicker } from 'v-calendar'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'

export default {
  name: 'DateRangeNavigator',
  components: { FontAwesomeIcon, VDatePicker },
  props: {
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    }
  },
  emits: ['update:start', 'update:end', 'dates-changed'],
  data() {
    return {
      dayClicked: 0
    }
  },
  computed: {
    localStart: {
      get() {
        return moment(this.start)
      },
      set(value) {
        this.$emit('update:start', value.format('YYYY-MM-DD'))
      }
    },
    localEnd: {
      get() {
        return moment(this.end)
      },
      set(value) {
        this.$emit('update:end', value.format('YYYY-MM-DD'))
      }
    },
    datePickerValues: {
      get() {
        return {
          start: this.localStart.toDate(),
          end: this.localEnd.toDate()
        }
      },
      set(value) {
        this.localStart = moment(value.start)
        this.localEnd = moment(value.end)
      }
    },
    diffDay() {
      return this.localEnd.diff(this.localStart, 'days') + 1
    },
    buttonClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-1 text-xs'
        case 'small':
          return 'px-3 py-1.5'
        case 'large':
          return 'px-5 py-3'
        case 'medium':
        default:
          return 'px-4 py-2'
      }
    }
  },
  methods: {
    decreaseRange() {
      this.localStart = this.localStart.subtract(this.diffDay, 'days')
      this.localEnd = this.localEnd.subtract(this.diffDay, 'days')
      this.$emit('dates-changed')
    },
    decreaseDay() {
      this.localStart = this.localStart.subtract(1, 'days')
      this.localEnd = this.localEnd.subtract(1, 'days')
      this.$emit('dates-changed')
    },
    increaseDay() {
      this.localStart = this.localStart.add(1, 'days')
      this.localEnd = this.localEnd.add(1, 'days')
      this.$emit('dates-changed')
    },
    increaseRange() {
      this.localStart = this.localStart.add(this.diffDay, 'days')
      this.localEnd = this.localEnd.add(this.diffDay, 'days')
      this.$emit('dates-changed')
    },
    handleDayClick() {
      if (this.dayClicked === 0) {
        this.dayClicked++
      } else {
        this.dayClicked = 0
        this.$emit('dates-changed')
      }
    }
  }
}
</script>
